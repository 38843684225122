/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import t from "../request.js";
import e from "./Error.js";
import { isAbortError as r } from "./promiseUtils.js";
import { dataComponents as a } from "./urlUtils.js";
import { base64ToArrayBuffer as n } from "../support/base64Utils.js";
let i = null,
  o = !0;
function s(t, e, r) {
  if (!t || !e) throw new Error("Cannot construct image data without dimensions");
  if (o) try {
    return new ImageData(t, e);
  } catch (a) {
    o = !1;
  }
  return m(t, e, r);
}
function c(t, e, r, a) {
  if (!e || !r) throw new Error("Cannot construct image data without dimensions");
  if (o) try {
    return new ImageData(t, e, r);
  } catch (i) {
    o = !1;
  }
  const n = m(e, r, a);
  return n.data.set(t, 0), n;
}
function f() {
  return i || (i = document.createElement("canvas"), i.width = 1, i.height = 1), i;
}
function m(t, e, r) {
  return r || (r = f()), r.getContext("2d").createImageData(t, e);
}
async function u(a, n) {
  const i = window.URL.createObjectURL(a);
  try {
    const {
      data: e
    } = await t(i, {
      ...n,
      responseType: "image"
    });
    return e;
  } catch (o) {
    if (!r(o)) throw new e("invalid-image", `Could not fetch requested image at ${i}`);
    throw o;
  } finally {
    window.URL.revokeObjectURL(i);
  }
}
async function p(t, e) {
  const {
      arrayBuffer: r,
      mediaType: a
    } = await d(t, e),
    n = "image/png" === a;
  if ("image/gif" === a) {
    const {
      isAnimatedGIF: t,
      parseGif: a
    } = await import("./image/gif.js");
    if (t(r)) return a(r, e);
  }
  if (n) {
    const {
      isAnimatedPNG: t,
      parseApng: a
    } = await import("./image/apng.js");
    if (t(r)) return a(r, e);
  }
  return u(new Blob([r], {
    type: a
  }), e);
}
async function d(e, r) {
  const i = a(e);
  if (i?.isBase64) return {
    arrayBuffer: n(i.data),
    mediaType: i.mediaType
  };
  const o = await t(e, {
    responseType: "array-buffer",
    ...r
  });
  return {
    arrayBuffer: o.data,
    mediaType: o.getHeader?.("Content-Type") ?? ""
  };
}
export { s as createEmptyImageData, p as getImageData, c as wrapImageData };